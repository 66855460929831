// Webpack Imports
import * as bootstrap from 'bootstrap';
 
import { Fancybox } from "@fancyapps/ui";
 
import Swiper, { Navigation, Pagination, EffectCards, Autoplay   } from 'swiper';

//import { Gradient } from './Gradient.js'

Swiper.use([EffectCards, Autoplay]);

 


// import * as THREE from 'three';
// import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
// import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';

// import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'

import { gsap } from 'gsap';
// import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
//import { ScrollSmoother } from 'gsap/ScrollSmoother';

 console.log('>>>ScrollSmoother<<<');
//console.log(ScrollSmoother);

// import { do3D } from  './tresde';
// console.log(do3D);

import Splitting from "splitting";




 
 //import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';

 gsap.registerPlugin( ScrollTrigger       );


 let smoother ; 
let tiempo =0;

let  miScrollBar;

let jsonListoDias = new Object();

let swiperHome;

let miJSON;

let logo_brand = document.getElementById('logo_brand');

let hayTelon = false;
let telon;

let body = document.getElementsByTagName('body');
body = body[0];
let header ;
// header = header[0];




let feedES = body.dataset.feedes;
let feedVAL = body.dataset.feedval;

var eventSourceLink;

if (document.documentElement.lang === "es-ES") {
	eventSourceLink = feedES;
  } else {
	eventSourceLink = feedVAL; 
  }

 


let posScroll  = 0; 

let currentOutScroll ;

let stopRender = false;

let miScroll;


let swiperPremios;

//VARS HOME
let iniSeconds;
let finSeconds;
let secsInADay;

let iniSec;
let finSec;

let selectedDate;

let anchoW;
let altoW;
 
let arrBotones;

let info_evento;
let in_info_evento;

let hoyMili;


let goToDia = 0 ;  
let contDia = 0;


let monthsOfYear = [
	'Gener',
	'Febrer',
	'Març',
	'Abril',
	'Maig',
	'Juny',
	'Juliol',
	'Agost',
	'Septembre',
	'Octubre',
	'Novembre',
	'Decembre',
	];




let monthsOfYearPeq = [
		'Gen',
		'Feb',
		'Mar',
		'Abr',
		'Mai',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
		];

let daysOfWeek = [  'Du', 'Dll', 'Dm', 'Dx', 'Dj', 'Dv', 'Ds'];	

console.log('VAL!!!!');

console.log(document.documentElement.lang);

if (document.documentElement.lang.indexOf("ES") !=-1) {

	console.log('SPANISH!!!!');

	monthsOfYear = [
		'Enero',
		'Febrero',
		'Marzo',
		'Abril',
		'Mayo',
		'Junio',
		'Julio',
		'Agosto',
		'Septiembre',
		'Octubre',
		'Noviembre',
		'Diciembre',
		];
	monthsOfYearPeq = [
			'Ene',
			'Feb',
			'Mar',
			'Abr',
			'May',
			'Jun',
			'Jul',
			'Ago',
			'Sep',
			'Oct',
			'Nov',
			'Dic',
			];
	daysOfWeek = [ 'Do' ,'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'];	

}

let WeekList; 
let monthTitle;
let yearTitle;
let today;

//VARS HOME


//VARS SINGLE ESPECTAUCLO//

let swiperSingle;

 


//FIX TITS

var arrTitsAlt ;
var altCompare = 0 ;
 
		 


//BODY
(function () {
	'use strict';

	console.log('!!!! HOME!!!'); 
 


})();

 
(function () {
	'use strict';


	console.log('HELLO!!!!');

	// Focus input if Searchform is empty
	[].forEach.call(document.querySelectorAll('.search-form'), (el) => {
		el.addEventListener('submit', function (e) {
			var search = el.querySelector('input');
			if (search.value.length < 1) {
				e.preventDefault();
				search.focus();
			}
		});
	});

	// Initialize Popovers: https://getbootstrap.com/docs/5.0/components/popovers
	var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
	var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
		return new bootstrap.Popover(popoverTriggerEl, {
			trigger: 'focus',
		});
	});

 


	//.mySwiperHome

	if(document.querySelector('.mySwiperHome')){

		console.log('!!!!!!!!!DO BANNER');
 
		doBanner();
	}

	if(body.classList.contains('home')){

		console.log('comon do home!!!');
		console.log(body);

		doHome();
 

	}
	if(body.classList.contains('page-template-template-quienes')){

		doQuienes();
 

	}

	if(body.classList.contains('page-template-template-servicios')){

		console.log('comon do servicios!!!');
		console.log(body);

		doServicios();
 

	}

	if(body.classList.contains('page-template-template-premios-horizontal')){

		doPremiosHorizontal();
 

	}

	if(body.classList.contains('mostrar_3D')){

		console.log('!!! BÁSICO 3D hago DO 3D!!!');

	// do3D();

 
	}


	let fancyItems= document.querySelectorAll('.fancybox');


	console.log('>>>>>>>>>fancyItems');

	console.log(fancyItems);
	
	if(fancyItems && fancyItems.length>0){

	 

		doFancy();

 
	}
 
	

	 
	window.onload = function(){


		//initSmooth();
		
		initParallax();
		
		fixMenu();

		resize();

		fixThings();
 
		initHome();

		doObserver();

		initRequest();

		
		if(body.classList.contains('page-template-template-servicios')){

			console.log('comon do servicios!!!');
			console.log(body);

			doServicios();
	

		}

		/*
		// console.log('>>>>>>>>>> smoother');
		// console.log(smoother);


		//  smoother = ScrollSmoother.create({
		// 	smooth: 2,
		// 	effects: true,
		// 	normalizeScroll: true
		//   });
		*/


		 // createGradient();

		 iniFotos();

	}

	
})(); ///END ONLOAD


var arrFotos;

function iniFotos() {
	arrFotos = document.querySelectorAll('.change_color');

	if(arrFotos.length > 0) {
	
		arrFotos.forEach(f=>{
			f.addEventListener('click', ev=>{
				let tmpFoto = ev.currentTarget;
				tmpFoto.classList.toggle('foto_b_n');
			})
		});
	
	
	}

}


var arrBotonesServ;
var arrContServ;

function abroPestServ(bot){

	console.log('!!!!!!!!!!!CLICK!!!!');
	console.log(bot);


	var tabBot = bot.parentNode.nextSibling;

	

	console.log('!!!!!!!!!!!SIBLING!!!!');
	console.log(tabBot);

	 tabBot.classList.toggle('abreTab');


}

function doServicios(){

	console.log('DO SERVICIOS!');

	  arrBotonesServ = document.querySelectorAll('.bot_nom_serv');
	  arrContServ = document.querySelectorAll('.cont_serv_expli'); 

	  arrBotonesServ.forEach((b)=>{

		console.log('BBBBBBBBBBB');

		console.log(b);

		b.addEventListener('click', (ev)=>{
			ev.preventDefault();
			abroPestServ(ev.currentTarget);
		}, true)

	})
}



let f_latin;
let f_es;
function initHome(){

	f_latin = document.getElementById('f_latin');
	f_es = document.getElementById('f_es');

	if(f_latin){

		f_latin.classList.add('aparece_t');
		
	}

	if(f_es){

		f_es.classList.add('aparece_t');
		
	}


}

 



function fixThings(){

	//console.log('fixThings GRID');

	//ARreglo títulos de menú //grid_espec_tit

	arrTitsAlt = document.querySelectorAll('.cont_altura');

	if(arrTitsAlt ){

		arrTitsAlt.forEach(( element, key)=>{
			// console.log('elemento grid key');
			// console.log(element);
			// console.log('key');
			// console.log(key);

			let tmpAlt = element.getBoundingClientRect();
			tmpAlt = tmpAlt.height;

			//console.log('ALT tmpAlt ' + tmpAlt + 'ALT COMPARE ' + altCompare);

			altCompare = Math.max(altCompare, tmpAlt);

			//console.log('ALT COMPARE ' + altCompare);

		})


		arrTitsAlt.forEach(( element, key)=>{
	
			element.style.minHeight = `${altCompare}px`;

		})
 
	}




	var arrTitsServ  = document.querySelectorAll('.grupo_in_servicios h2');
	var tmpAltServ = 0;

	
	//console.log('[-------] arrTitsServ '   );

	//console.log(arrTitsServ);



	if(arrTitsServ){


		arrTitsServ.forEach(( element, key)=>{
		

				let tmpAlt = element.getBoundingClientRect();
				tmpAlt = tmpAlt.height; 

				//console.log('tmpAlt > ' + tmpAlt );


				altCompare = Math.max(tmpAltServ, tmpAlt);
		

			});

			arrTitsServ.forEach(( element, key)=>{
		
				element.style.minHeight = `${altCompare}px`;

			})



	}

	//console.log('>>>>>>>> ARR TITULOS ALT');


	reposFotoKira();

	igualaTitulos();
	

	console.log('END fixThings !!!!!!!!!!!');

}

function igualaTitulos(){

// cont_iguala
// iguala

var arreglosTitsCont = document.querySelectorAll('.cont_iguala');


//console.log('------arreglosTitsCont');
//console.log(arreglosTitsCont);

arreglosTitsCont.forEach((cont)=>{

	var alt=0;

	var arrTits = cont.querySelectorAll('.iguala');

	arrTits.forEach((t)=>{
		var tmpT = t.getBoundingClientRect();
		var altTit = tmpT.height;
		alt = Math.max(alt, altTit);
		//console.log('------ALT');
		console.log(alt);
	})

	arrTits.forEach((t)=>{

		//console.log('-------T');
		//console.log(t);

		t.style.height = alt+'px';
	})

})


}


function reposFotoKira(){



	//FOTO BOX 

	let infoQuien = document.getElementById('info_box_kira');
	let fotoQuien = document.getElementById('foto_box_kira');

	if(fotoQuien){

		infoQuien= infoQuien.getBoundingClientRect();
		console.log('>>>>> topQui');
		console.log(infoQuien);
		let topQui = infoQuien.top;
		let altQui = infoQuien.height;
		console.log('ZZZZZZZZ topQui');
		console.log(topQui);

		fotoQuien.style.top = topQui+'px';
		fotoQuien.style.height = altQui+'px';

		console.log('END reposFotoKira !!!!!!!!!!!');


	}


	 

}



function doFancy(){

	console.log('DO FANCY');

	Fancybox.bind("[data-fancybox]", {
		// Your custom options
	  });
}

function fixMenu(){

	console.log('FIX MENU!!!');

	//menu-item-language

	var menuLang= document.querySelectorAll('.menu-item-language span[lang]');

	// var menuHeader = document.getElementById('menu-menu-principal');
	var menuHeader = document.querySelector('.menu_thunder');
	var movilRRSS = document.getElementById('rrss_thunder_header_movil');

	if(menuHeader){
		var li = document.createElement("li");
		menuHeader.appendChild(li);
		li.appendChild( movilRRSS  );



	}




	console.log('menuLang');
	console.log(menuLang);

	Array.prototype.forEach.call(menuLang ,(m)=>{

		let tmpIdio = m.lang;
		m.innerHTML = tmpIdio;
		m.classList.add('upperCase');

		m.classList.add('muevoLeft');

		console.log('menulang');
		console.log(m);
	})



}




function quitoActivo(_arr, _clase){


	console.log('quitoActivo!!!!! ');

	Array.prototype.forEach.call(_arr, (b)=>{

		console.log('QUITO!!!!! ' + _clase);
		console.log(b);

		b.classList.remove(_clase);

	});
}


function doBanner(){


	swiperHome = new Swiper('.mySwiperHome', {
		speed: 600,
		autoplay: {
			delay: 5000,
		  },
		parallax: true, 
		navigation: {
		  nextEl: '.swiper-button-next-home',
		  prevEl: '.swiper-button-prev-home',
		},
		
	  });

	  swiperHome.on('slideChange', function () {
 
		quitoActivo(arrBotonesBanners, 'activo');

 
		arrBotonesBanners[(this.activeIndex)].classList.add('activo');

		 
	  });

	  var arrBotonesBanners = document.querySelectorAll('.list_botonera a');
	  Array.prototype.forEach.call(arrBotonesBanners, (b)=>{
		console.log('bbbbb ' + b);
		b.addEventListener('click', (ev)=>{
			ev.preventDefault();
			quitoActivo(arrBotonesBanners, 'activo');
			ev.currentTarget.classList.add('activo');
			
			let slideGo = ev.currentTarget.dataset.cont;
			console.log('SLIDEGTO ' + slideGo);
			swiperHome.slideTo(slideGo);

		})
	  })
 

}


function doHome(){

	
	console.log('doHome');
 
	//console.log(Swiper);



	//miJSON =   leeJSON(eventSourceLink);

 


}



function setDate(utc){

	let tmpDate =   new Date( utc );  
	return   tmpDate;
  
  
  
  }
  
  function setUTC(year,month , day){
  
	let now_utc =  new Date(year, month,day); 
	now_utc = now_utc.getTime(); 
	return now_utc; 
  }
  
  function date2Mili(fecha){

	//Sun Apr 23 2023 12:28:47 GMT+0200 (hora de verano de Europa central)
  
  
	let miFecha = new Date(fecha);
  
	//console.log('fecha.getMilliseconds()');
  
	//console.log(miFecha );
  
  
  }

 
 
  

function doQuienes(){

var galerias = document.querySelectorAll('.swiper_gallery');

var arrSwipers = [];

var cont = 0 ;

Array.prototype.forEach.call(galerias, (g)=>{

	let tmpID =  g.id;

	tmpID= tmpID.replace(' ', '');

	tmpID = '#' + tmpID;


	console.log('>>>>> tmpID');

	console.log(tmpID);


	let swiperGaleria = new Swiper(g, {

		slidesPerView: 1,
		spaceBetween: 10,
		centeredSlides: true,
		initialSlide:1,

		breakpoints: {
			// when window width is >= 320px
		 
			// when window width is >= 640px
			640: {
			  slidesPerView: 2,
			  spaceBetween: 40
			}, 
			1024: {
				slidesPerView: 2,
				spaceBetween: 40
			  }
		  },
		  navigation: {
			nextEl: tmpID+' .swip_gallery_right',
			prevEl: tmpID+' .swip_gallery_left',
		  },

 


	});


	arrSwipers.push(swiperGaleria);

		  /*

		  */

		  console.log(tmpID+' .swip_gallery_left');

		  console.log(tmpID+' .swip_gallery_right');
	let tmpBotIZQ = document.querySelectorAll(tmpID+' .swip_gallery_left');

	let tmpBotDER = document.querySelectorAll(tmpID+' .swip_gallery_right');

	

	if(tmpBotDER[0]){
		tmpBotDER[0].dataset.galeria = cont;// swiperGaleria;
		console.log('HAY DER');
		tmpBotDER[0].addEventListener('click', (ev)=>{
			console.log('DER');
			let tmpGal = Number(ev.currentTarget.dataset.galeria);
			console.log('TMP GAL !!!!');
			console.log(tmpGal);

			 arrSwipers[tmpGal].slideNext();
			//ev.preventDefault();
			//g.slideNext();
		  });

	  }


	  if(tmpBotIZQ[0]){
		console.log('HAY IZQ');
		tmpBotIZQ[0].dataset.galeria = cont;// swiperGaleria;
		tmpBotIZQ[0].addEventListener('click', (ev)=>{
			console.log('IZQ');
			let tmpGal = Number(ev.currentTarget.dataset.galeria);
			console.log('TMP GAL !!!!');
			console.log(tmpGal);

			 arrSwipers[tmpGal].slidePrev();
			//ev.preventDefault();

			//g.slidePrev();
		  })

	  }

	
	  cont++;

})


var swiperGaleria = document.getElementById('banner_galeria');



if(swiperGaleria){



	let swiperGaleriaGran = new Swiper(swiperGaleria, {

		slidesPerView: 1, 
		centeredSlides: true, 
		speed:600,
		autoplay: {
			delay: 5000,
		  }, 
 
	}); 


	var arrBotonesBannersGal = document.querySelectorAll('.list_botonera a');
	Array.prototype.forEach.call(arrBotonesBannersGal, (b)=>{
	  console.log('bbbbb ' + b);
	  b.addEventListener('click', (ev)=>{
		  ev.preventDefault();
		  quitoActivo(arrBotonesBannersGal, 'activo');
		  ev.currentTarget.classList.add('activo');
		  
		  let slideGo = ev.currentTarget.dataset.cont;
		  console.log('SLIDEGTO ' + slideGo);
		  swiperGaleriaGran.slideTo(slideGo);

	  })
	})

 
 

}


}//doQuienes
 

///SMOOTH WHEEL

//MOUSEWHEEL

function initSmooth(){

	console.log('>>>>>>>>>>> initSmooth');


	new SmoothScroll(document,120,12)
  }
  
  function SmoothScroll(target, speed, smooth) {
	if (target === document)
	  target = (document.scrollingElement
				|| document.documentElement
				|| document.body.parentNode
				|| document.body) // cross browser support for document scrolling
  
	var moving = false
	var pos = target.scrollTop
	var frame = target === document.body
				&& document.documentElement
				? document.documentElement
				: target // safari is the new IE
  
	target.addEventListener('mousewheel', scrolled, { passive: false })
	target.addEventListener('DOMMouseScroll', scrolled, { passive: false })
  
	function scrolled(e) {
	  e.preventDefault(); // disable default scrolling
  
	  var delta = normalizeWheelDelta(e)
  
	  pos += -delta * speed
	  pos = Math.max(0, Math.min(pos, target.scrollHeight - frame.clientHeight)) // limit scrolling
  
	  if (!moving) update()
	}
  
	function normalizeWheelDelta(e){
	  if(e.detail){
		if(e.wheelDelta)
		  return e.wheelDelta/e.detail/40 * (e.detail>0 ? 1 : -1) // Opera
		else
		  return -e.detail/3 // Firefox
	  }else
		return e.wheelDelta/120 // IE,Safari,Chrome
	}
  
	function update() {
	  moving = true
  
	  var delta = (pos - target.scrollTop) / smooth
  
	  target.scrollTop += delta
  
	  if (Math.abs(delta) > 0.5)
		requestFrame(update)
	  else
		moving = false

		checkParallax(delta);
	}
  
	var requestFrame = function() { // requestAnimationFrame cross browser
	  return (
		window.requestAnimationFrame ||
		window.webkitRequestAnimationFrame ||
		window.mozRequestAnimationFrame ||
		window.oRequestAnimationFrame ||
		window.msRequestAnimationFrame ||
		function(func) {
		  window.setTimeout(func, 1000 / 50);
		}
	  );
	}()
  }
  
var arrParallax = document.querySelectorAll('.soyParallax');

let prePer =0 ;


function initParallax(){


	window.addEventListener('scroll', function(){

		if(body.classList.contains('home')){
			 amountscrolled();			
		}


 
		checkParallax();
	}, false)
  

}


  function checkParallax(delta){


	if(hayTelon){

		if(window.scrollY>window.innerHeight){
			cont_ficha.classList.remove('pos-fixed');
			extratelon.classList.add('d-none');
		}else{ 
			cont_ficha.classList.add('pos-fixed');
			extratelon.classList.remove('d-none');

			telon.classList.remove('events-none');

		}

		if(window.scrollY>(window.innerHeight + 150)){
			telon.classList.add('events-none');
		}

		//


	}


	//console.log('checkParallax');
	//console.log(delta);

	// let per = 	amountscrolled();

	let per = 	scrollDesplazado();

	if(arrParallax.length>0){

	

		if(per!= prePer){

			console.log(per);
				Array.prototype.forEach.call(arrParallax, (p)=>{

					// console.log('p');

					let ratio =  Number(p.dataset.parallath);

					if(!ratio){
						ratio = 1;
					}
					

				
					
					p.style.transform = 'translate3D(0, '+ (-1*per*ratio)  + '%, 0)';

				});


		}

 

	}

	//console.log('per ' + per );
	

	if(per >= prePer && per>=50){

		body.classList.add('despBaix');
	}else if(per<=50) { 
		body.classList.remove('despBaix');
	}

	
	prePer = per;


 

	// if(logo3DOpaco){
	// 	let per = 	amountscrolled();

	// }



  } //checkParallax


  function getDocHeight() {
	var D = document;
	return Math.max(
		D.body.scrollHeight, D.documentElement.scrollHeight,
		D.body.offsetHeight, D.documentElement.offsetHeight,
		D.body.clientHeight, D.documentElement.clientHeight
	)
}


  function amountscrolled(){
	var winheight= window.innerHeight || (document.documentElement || document.body).clientHeight
	var docheight = getDocHeight()
	var scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop
	var trackLength = docheight - winheight
	var pctScrolled = Math.floor(scrollTop/trackLength * 100) // gets percentage scrolled (ie: 80 or NaN if tracklength == 0)
	//  console.log(pctScrolled + '% scrolled');
	//  console.log(scrollTop + '% scrollTop');

	
		if(body.classList.contains('home')){

			if(scrollTop > 150){
				body.classList.add('muestraLogo');
			}else{
				body.classList.remove('muestraLogo');
			} 
		}

	// console.log('logo3DOpaco');

	// console.log(logo3DOpaco);

	// if(logo3DOpaco){
	// 	interact3D(pctScrolled , scrollTop);
	// }

	return(pctScrolled);



}


function scrollDesplazado(){
	var winheight= window.innerHeight || (document.documentElement || document.body).clientHeight
	var docheight = getDocHeight()
	var scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop
	//var trackLength = docheight - winheight
	//var pctScrolled = Math.floor(scrollTop/trackLength * 100) // gets percentage scrolled (ie: 80 or NaN if tracklength == 0)
	//console.log(pctScrolled + '% scrolled');

	// console.log('logo3DOpaco');

	// console.log(logo3DOpaco);

	// if(logo3DOpaco){
	// 	interact3D(pctScrolled , scrollTop);
	// }

	return(scrollTop);



}


//CLICK MENÚ

var arrMenuBots = document.querySelectorAll('.dropdown-toggle');

Array.prototype.forEach.call(arrMenuBots, (b)=>{
	b.addEventListener('click', (ev)=>{

		if(ev.target){

				console.log('click > ');
				console.log(ev); 
				console.log(ev.target.tagName);

				if(body.classList.contains('show_menu')){
					body.classList.remove('show_menu');
				}else{
					body.classList.add('show_menu');
				}

				if(ev.target.tagName=='A'){

					console.log('click AAA > ');
					console.log(ev);

					if(ev.target.classList.contains('dropdown-toggle')){
						body.classList.add('show_menu')
					}
				}


		}

 
	});
} );

document.addEventListener('click', (ev)=>{
	//console.log('document click');
	//console.log(ev.target.tagName);

	if(ev.target.tagName=='DIV'){
		if(body.classList.contains('show_menu')){
			body.classList.remove('show_menu')
		}
 
		//console.log('ev.currentTarget');
		//console.log(ev.target.classList);
		//console.log(ev.target);
		

		if(!ev.target.classList.contains('select-btn')){

				var hayFiltro = document.querySelectorAll('.select-btn.open')	;

				console.log('hayFiltro');
				console.log(hayFiltro);

				if(hayFiltro){
						
						console.log('hayFiltro.childNodes.length');
						console.log(hayFiltro.length);

						if(hayFiltro.length>0){
							console.log('FILTRO ABIERTO');

							console.log('HAY FILTRO');
							console.log(hayFiltro);

							Array.prototype.forEach.call(hayFiltro, (f)=>{
								f.dispatchEvent(new Event('click'));
							})
						}

				}

		}

 

	}
 
}) //document.addEventListener('click'




window.addEventListener('resize', generalResize);

function generalResize(){

	//THREEJS 

	// anchoW = miCanvas.offsetWidth;
	// altoW = miCanvas.offsetHeight;
	//  renderer.setSize(anchoW , altoW);
	//  camera.aspect = anchoW/altoW;
	//  camera.updateProjectionMatrix();


	 //THREEJS 
}

var cont_ficha;

var extratelon ;
 

 
function resize(){

	console.log('RESIZEEEEE!!!!!!!!!!!!!!!!!!!!');

	/*
let box = document.querySelector('.box');
let width = box.offsetWidth;
let height = box.offsetHeight;

	*/


	var arrVideos = document.querySelectorAll('.section_video iframe');

	Array.prototype.forEach.call(arrVideos, (vi)=>{

		var tmpAnch = Number(vi.width);
		var tmpAlt  = Number(vi.height);
		var prop = tmpAnch/tmpAlt

		console.log('prop' + prop);

		vi.dataset.prop = prop;

		// var anchoTrue = vi.getBoundingClientRect();
		// anchoTrue = anchoTrue.width;

		// vi.style.height = (anchoTrue / prop)+'px';

		// console.log( (anchoTrue / prop)+'px'   );


	})

}


function resizeo(){

	reposFotoKira();


}

window.addEventListener('resize', resizeo);

//HAY IMG HERO

var imgHERO = document.getElementById('imghero');

if(imgHERO){


gsap.to(imgHERO, {
	scrollTrigger: {
	trigger: "body",
	start: "top top",
	end: "bottom top", 
	scrub: true,
	toggleActions: "restart pause resume pause"
},
	y: "+=100" 
});

}



//OBSERVADOR SCROLL

let observerConfig ;

let observador;


let observerConfigMenu ;

let observadorMenu;

let arrIntesection = [];

function addIntersectionFrame(_item){

	arrIntesection.push(_item);

}
function borroIntersectionFrame(_item){

	let tmpPos = arrIntesection.indexOf(_item);

	if(tmpPos>-1){
		arrIntesection.splice(tmpPos, 1);
	}


	// console.log('arrIntesection fuera');
	// console.log(arrIntesection);

}


let start, previousTimeStamp;
let done = false;


function initRequest(){
	
	window.requestAnimationFrame(animateFunction);
}


function checkOutBounds( p ){

	if(p){

		let tmpPos = p.getBoundingClientRect();

		// console.log('>>>>>> P');
		// console.log(p.getAttribute('id'));
		
		// console.log('TMPOOSSS');

		if(tmpPos.top <= 0){

				if(p.classList.contains('es_fon_negro')){
					if(header){
						header.classList.add('menu_white');
						header.classList.remove('menu_black');

					}

				}

				if(p.classList.contains('es_fon_blanco')){

					if(header){
						header.classList.add('menu_black');
						header.classList.remove('menu_white');

					}

				}

		}


		

		


	}

}

function animateFunction(timeStamp) {
	
	if (start === undefined) {
	  start = timeStamp;
	}
	const elapsed = timeStamp - start;
  
	if (previousTimeStamp !== timeStamp) {
	  // Math.min() is used here to make sure the element stops at exactly 200px
	 // const count = Math.min(0.1 * elapsed, 200);
	 // element.style.transform = `translateX(${count}px)`;
	 // if (count === 200) done = true;
	}
  
	// if (elapsed < 2000) {
	//   // Stop the animation after 2 seconds
	//   previousTimeStamp = timeStamp;
	//   if (!done) {
	// 	window.requestAnimationFrame(step);
	//   }
	// }

	
	arrIntesection.forEach( (p)=>{
		checkOutBounds( p );;
	} );

	
 

	// if((Math.floor(elapsed) % 100) == 0){

	// 	console.log(Math.floor(elapsed));
	// 	console.log('arrIntesection'); 
	// 	console.log(arrIntesection);
	// }


	window.requestAnimationFrame(animateFunction);

	//console.log('REQUEST');

  }
  


var callbackScrollMenu = function (entries, observer) {
	entries.forEach((entry) => {


		header = document.querySelector('#header');


	

		if(entry.isIntersecting){


				console.log('entry.target.id') ;
				console.log(entry.target.getAttribute('id')) ;

				console.log('>> entry.intersectionRect');

			console.log(entry.intersectionRect);

			console.log('entry.rootBounds');

			console.log(entry.rootBounds);

			addIntersectionFrame(entry.target);

			
			
			console.log('METO ARR INTESERCTIONS');
			console.log(arrIntesection);
			
 

			// if(entry.target.classList.contains('es_fon_negro')){
			// 	header.classList.add('menu_white');
			// 	//header.classList.remove('menu_black');
			// }

			// if(entry.target.classList.contains('es_fon_blanco')){
			// 	header.classList.add('menu_black');
			// 	//header.classList.remove('menu_white');
			// }
 
		}else{

			borroIntersectionFrame(entry.target);





			// if (entry.intersectionRatio >= 1) {

			// 	console.log('entry.target.id') ;
			// 	console.log(entry.target.getAttribute('id')) ;

			// }



			// if(entry.target.classList.contains('es_fon_negro')){
			// 	header.classList.remove('menu_white');
			// }

			// if(entry.target.classList.contains('es_fon_blanco')){
			// 	header.classList.remove('menu_black');
			// }

 

			//entry.target.classList.remove('split_activo');

		}
	

	  // Cada entry describe un cambio en la intersección para
	  // un elemento observado
	  //   entry.boundingClientRect
	  //   entry.intersectionRatio
	  //   entry.intersectionRect
	  //   entry.isIntersecting
	  //   entry.rootBounds
	  //   entry.target
	  //   entry.time
	});
  };


var callbackScroll = function (entries, observer) {
	entries.forEach((entry) => {


 
		if(entry.isIntersecting){
			// console.log('INTERSECTA');
			// console.log(entry.target); 

			// console.log('header>>>>>>>>>>>');
			// console.log(header);

			entry.target.classList.add('entro_scroll');
			//entry.target.classList.add('split_activo');

			//es_fon_negro es_fon_blanco
 

			//console.log('ENTRO  >>>>>>>>>>>>>>');

			//console.log(entry.target.classList);
		}else{

 


			entry.target.classList.remove('entro_scroll');


			//console.log('SALGO  >>>>>>>>>>>>>>');

			//console.log(entry.target.classList);

			//entry.target.classList.remove('split_activo');

		}
	

	  // Cada entry describe un cambio en la intersección para
	  // un elemento observado
	  //   entry.boundingClientRect
	  //   entry.intersectionRatio
	  //   entry.intersectionRect
	  //   entry.isIntersecting
	  //   entry.rootBounds
	  //   entry.target
	  //   entry.time
	});
  };

  //logo_brand


  function doObserver(){

	////console.log('doSplit >>>>>>> 00000');
 
	observerConfig = {
		root: null,
		rootMargin: '0px',
		threshold: 0.1
	  };

	  observador = new IntersectionObserver(callbackScroll, observerConfig);

	  var arrObservo = document.querySelectorAll('.triggerOnScroll');

	  arrObservo.forEach(ob=>{

		observador.observe(ob);

		//console.log(ob);


	  });


	   
		observerConfigMenu = {
			root: null,
			rootMargin: '0px',
			threshold: 0 // 1 se activa cunando el elemento se ve al 100$
		};

	  observadorMenu = new IntersectionObserver(callbackScrollMenu, observerConfigMenu);

	  var arrObservoMenu = document.querySelectorAll('.triggerOnScrollHome');

	  arrObservoMenu.forEach(ob=>{

		observadorMenu.observe(ob);

		//console.log('>>>>>>>>> ob');

	//	console.log(ob);


	  });


	  doSplit();



}


function doSplit(){

	
	Splitting({
		/* target: String selector, Element, Array of Elements, or NodeList */
		target: "[data-splitting]",
		/* by: String of the plugin name */
		by: "lines",
		/* key: Optional String to prefix the CSS variables */
		key: null
	  });

	  var inMaskArr = document.querySelectorAll('.splitting .word');

	  inMaskArr.forEach(inc=>{

		let tmpCont = inc.textContent;
		inc.innerHTML='';
		//let tmpSpan = document.createElement('span');
		inc.innerHTML+='<span class="in_mask_span">'+tmpCont+'</span>';
		//inc.appendChild(tmpSpan) ;


	  });

		//https://splitting.js.org/guide.html#grid

		//   //console.log('inMaskArr');

		//   //console.log(inMaskArr);


	 console.log(Splitting);;


}